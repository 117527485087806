<script>
import ERow from "../../../../layout/components/Row"
import ECol from "../../../../layout/components/Col"
import {showMovimentacao} from "@/domain/processos/services"
import {ErpSField} from "uloc-vue-plugin-erp"
import globalProcessoMixin from "@/components/processos/globalProcessoMixin"

export default {
  name: "MovimentacaoProcesso",
  mixins: [globalProcessoMixin],
  props: ['id', '$router'],
  components: {
    ECol,
    ERow,
    ErpSField
  },
  data () {
    return {
      movimentacao: {},
      loading: true
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      this.loading = true
      showMovimentacao(this.id)
      .then(response => {
        console.log(response)
        this.movimentacao = response.data
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<template>
  <div class="wrapper-md" v-if="!loading">
    <e-row>
      <e-col style="margin-right: 15px">
        <erp-s-field label="Processo">
          <a @click="gotoProcesso(movimentacao.processo)"><strong>{{movimentacao.processo.numero|formataNumeroProcesso}}</strong></a>
        </erp-s-field>
      </e-col>
      <e-col style="max-width: 150px; margin-right: 15px">
        <erp-s-field label="Código">
          <strong>{{movimentacao.codigo || '-'}}</strong>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field label="Código">
          <strong>{{movimentacao.codigo || '-'}}</strong>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field label="Publicação encontrada em">
          <strong v-if="movimentacao.dataPublicacao">{{movimentacao.dataPublicacao.date|formatDate('dd/MM/yyyy HH:mm:ss')}}</strong>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field label="Registrado no sistema em">
          <strong>{{movimentacao.createdAt.date|formatDate('dd/MM/yyyy HH:mm:ss')}}</strong>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row>
      <e-col>
        <erp-s-field label="Descrição">
          {{movimentacao.descricao || '-'}}
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row>
      <e-col class="m-t">
        <erp-s-field label="Conteúdo da publicação">
          <div style="word-break: break-word; white-space: break-spaces; border: #CCCCCC 1px dotted; padding: 10px" v-html="movimentacao.texto"></div>
        </erp-s-field>
      </e-col>
    </e-row>
  </div>
</template>
